import { ApolloClient } from 'apollo-client';

import { AwsConfig, awsConfigDev, awsConfigProd } from '../config/awsConfig';
import { GraphQL } from '../services/GraphQL/GraphQL';
export class Context {
  static apolloClient: ApolloClient<any>;

  static initialize() {
    const awsConfig: AwsConfig = process.env.REACT_APP_ENV === 'dev' ? awsConfigDev : awsConfigProd;

    Context.apolloClient = GraphQL.getClient({
      region: awsConfig.aws_appsync_region,
      url: awsConfig.aws_appsync_graphqlEndpoint,
      apiKey: awsConfig.aws_appsync_apiKey,
    }) as any; // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/362
  }
}
