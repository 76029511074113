import { FC, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Logo } from '../../Logo/Logo';

import './styles.css';
import codeLocatorImage from './locker-code-locator.jpg';

export const OrderCode: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isCodeInvalid = searchParams.get('invalid');
  const [codeId, setCodeId] = useState<string>('');

  useEffect(() => {
    if (codeId.length === 3) {
      navigate(`/${codeId}`);
    }
  }, [codeId, navigate]);

  return (
    <div className="body page">
      {isCodeInvalid && (
        <div className="container centered-box">
          <h1>Invalid Code</h1>
        </div>
      )}
      <div className="container centered-box mt-s">
        <div className="challenge-instructions">Enter code shown on the locker screen</div>
        <div className="challenge-section">
          <input
            onChange={(event) => setCodeId(event.target.value.toUpperCase().replace(/ /g, ''))}
            value={codeId}
            placeholder="_ _ _"
            pattern="[a-zA-Z0-9]*"
            className="challenge-input challenge-input-4-chars data-hj-allow"
            maxLength={3}
            autoFocus
          />
        </div>
        <div className="centered-box">
          <img src={codeLocatorImage} alt="Code Locator" className="code-locator-image" />
        </div>
      </div>
      <div className="container-full logo-container">
        <a href="https://goboxie.com" target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </div>
    </div>
  );
};
