/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChallengeType {
  FIRST_OF_LAST_NAME = "FIRST_OF_LAST_NAME",
  LAST_OF_EXTERNAL_ORDER = "LAST_OF_EXTERNAL_ORDER",
  LAST_OF_PHONE = "LAST_OF_PHONE",
}

export enum OrderStatus {
  cancelled = "cancelled",
  expired = "expired",
  fulfilled = "fulfilled",
  new = "new",
  preparing = "preparing",
  ready = "ready",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
