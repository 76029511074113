import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

export class GraphQL {
  static getClient({url, region, apiKey} : {url: string, region: string, apiKey: string}) {

    return new AWSAppSyncClient({
      url,
      region,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey,
      },
      disableOffline: true,
    })
  }
}