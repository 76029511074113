import { FC } from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { Context } from './context/Context';
import { Order } from './components/pages/Order/Order';
import { OrderCode } from './components/pages/Order/OrderCode';
import { Review } from './components/pages/Order/Review';

// import './App.css';

Context.initialize();

if (process.env.REACT_APP_ENV === 'prod') {
  hotjar.initialize(2814955, 6);
}

export const App: FC = () => {
  return (
    <ApolloProvider client={Context.apolloClient}>
      <BrowserRouter>
        <Routes>
          <Route path=":codeId" element={<Order />} />
          <Route path=":codeId/review" element={<Review />} />
          <Route path=":codeId/:challengeKey" element={<Order />} />
          <Route path="*" element={<OrderCode />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};
