export interface AwsConfig {
  aws_appsync_region: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_authenticationType: string;
  aws_appsync_apiKey: string;

  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
}

export const awsConfigDev: AwsConfig = {
  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: 'https://awp7hypruva6rjegrhwper47qq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-kohuzph6jvay3mmq42jxbnl6i4',

  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_alLb6CgBk',
  aws_user_pools_web_client_id: '3hp7klnre68cem4mnm3qs9ide3',
};

export const awsConfigProd: AwsConfig = {
  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: 'https://xrw3oup55rdq5k6hb3tvoo6vpq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-ikkln4w2vbdtjne2hldyrt3gr4',

  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_GmYIiLa8B',
  aws_user_pools_web_client_id: '42602n0qf3vvmac9kvpruj9sk6',
};
