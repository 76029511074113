import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { produce } from 'immer';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { hotjar } from 'react-hotjar';

import { GetOrderPublic, GetOrderPublicVariables } from '../../../types/gql/GetOrderPublic';
import { GET_ORDER_PUBLIC, ON_ORDER_UPDATED_PUBLIC } from '../../../repositories/OrderRepository';
import { ChallengeType, OrderStatus } from '../../../types/gql/globalTypes';
import { OpenDevicePublic, OpenDevicePublicVariables } from '../../../types/gql/OpenDevicePublic';
import { OPEN_DEVICE_PUBLIC } from '../../../repositories/DeviceRepository';
import { OnOrderUpdatedPublic, OnOrderUpdatedPublicVariables } from '../../../types/gql/OnOrderUpdatedPublic';
import { Logo } from '../../Logo/Logo';

import './styles.css';
import { LockerImage } from '../../LockerImage/LockerImage';

dayjs.extend(LocalizedFormat);
interface OrderRouteParams {
  codeId: string;
  challengeKey?: string;
}

const CHALLENGE: {
  [key in ChallengeType]: {
    validationError: string;
    isNumeric?: boolean;
    expectedLength: number;
  };
} = {
  [ChallengeType.LAST_OF_PHONE]: {
    validationError: 'Enter the last 4 digits of the phone number on the order.',
    isNumeric: true,
    expectedLength: 4,
  },
  [ChallengeType.FIRST_OF_LAST_NAME]: {
    validationError: 'Enter first letter of the last name of the person on the order.',
    expectedLength: 1,
  },
  [ChallengeType.LAST_OF_EXTERNAL_ORDER]: {
    validationError: 'Enter the last 4 digits of the order number.',
    expectedLength: 4,
  },
};

export const Order: FC = () => {
  const navigate = useNavigate();
  const { codeId, challengeKey } = useParams() as unknown as OrderRouteParams;
  const [challengeResponse, setChallengeResponse] = useState<string>('');
  const [maxAttemptsExceeded, setMaxAttemptsExceeded] = useState<boolean>(false);
  const [openedLocker, setOpenedLocker] = useState<boolean>(false);

  useEffect(() => {
    try {
      hotjar.identify(codeId, { challengeKey });
    } catch (e) {
      // do nothing :)
    }
  }, [codeId, challengeKey]);

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
    subscribeToMore,
  } = useQuery<GetOrderPublic, GetOrderPublicVariables>(GET_ORDER_PUBLIC, {
    variables: {
      codeId,
    },
  });

  useEffect(() => {
    subscribeToMore<OnOrderUpdatedPublic, OnOrderUpdatedPublicVariables>({
      document: ON_ORDER_UPDATED_PUBLIC,
      variables: {
        codeId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        console.log('subscription received', { subscriptionData });
        if (!subscriptionData.data) return prev;

        return produce(prev, (draft) => {
          const next = subscriptionData.data.onOrderUpdatedPublic;

          if (!draft.getOrderPublic || !next) {
            return;
          }

          draft.getOrderPublic.isOrderAssigned = next.isOrderAssigned;
          draft.getOrderPublic.deviceDispayNumber = next.deviceDispayNumber;
          draft.getOrderPublic.status = next.status;
          draft.getOrderPublic.displayInfo = next.displayInfo;
          draft.getOrderPublic.challengeType = next.challengeType;
          draft.getOrderPublic.isStale = next.isStale;
        });
      },
      onError: (e) => console.error('OnOrderUpdated:', e),
    });
  }, [codeId, subscribeToMore]);

  const [openDevice, { loading: openDeviceLoading }] = useMutation<OpenDevicePublic, OpenDevicePublicVariables>(
    OPEN_DEVICE_PUBLIC
  );

  const attemptOpeningDevice = async () => {
    try {
      await openDevice({
        variables: {
          codeId,
          challenge: challengeKey ?? challengeResponse,
        },
      });

      setOpenedLocker(true);
    } catch (e) {
      // @ts-ignore
      const errorCode = e?.graphQLErrors[0]?.message;
      console.info(errorCode);

      switch (errorCode) {
        case 'PUBLIC_API_INVALID_ANSWER':
          alert('Invalid answer, please try again.');
          setChallengeResponse('');
          break;
        case 'PUBLIC_API_ATTEMPTS_EXCEDED':
          setMaxAttemptsExceeded(true);
          break;
        default:
          alert(errorCode);
          break;
      }
    }
  };

  const handleChallengeValidation = () => {
    const challengeType = orderData?.getOrderPublic?.challengeType;

    if (!challengeKey && challengeType) {
      if (challengeResponse.length !== CHALLENGE[challengeType].expectedLength) {
        return alert(CHALLENGE[challengeType].validationError);
      }
    }

    attemptOpeningDevice();
  };

  if (orderLoading) {
    return (
      <div className="body">
        <div className="container container-full">
          <h2>Loading order...</h2>
        </div>
      </div>
    );
  }

  if (orderError) {
    console.error(orderError);
    navigate('/?invalid=true');
    return null;
  }

  const {
    status,
    preparingAt,
    location,
    deviceDispayNumber,
    displayInfo,
    challengeType,
    attemptsRemaining,
    isStale,
    preventStalePickup,
    isOrderAssigned,
  } = orderData!.getOrderPublic!;

  if (!openDeviceLoading && openedLocker) {
    navigate(`/${codeId}/review`);
    return null;
  }

  let estimatedAt;

  if (preparingAt && location.avgPrepTime) {
    estimatedAt = dayjs(preparingAt).add(location.avgPrepTime, 'minutes');
  }

  if (challengeType !== null && (maxAttemptsExceeded || attemptsRemaining <= 0)) {
    return (
      <div className="body">
        <div className="container container-full">
          <h2>Too many attempts</h2>
          <h4>Please ask the staff for your order</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="body page">
      {status === OrderStatus.preparing && (
        <div className="container centered-box">
          <h1>Preparing order for {displayInfo}</h1>
          {estimatedAt && <h5>Estimated for {estimatedAt.format('LT')}</h5>}
        </div>
      )}

      {preventStalePickup && isStale ? (
        <div className="container container-full mt-s">
          <h2>Order waited for longer than usual</h2>
          <h3>Please ask the staff for your order</h3>
        </div>
      ) : isOrderAssigned ? (
        <div className="container centered-box mt-s">
          {status === OrderStatus.preparing && (
            <div className="container">
              <div className="challenge-instructions">Use this page to open the locker when it's ready</div>
            </div>
          )}

          {status === OrderStatus.ready && challengeKey && (
            <div className="container centered-box">
              <h1>Order is ready for pickup</h1>
            </div>
          )}

          {status === OrderStatus.ready && !challengeKey && challengeType === ChallengeType.LAST_OF_PHONE && (
            <>
              <div className="challenge-instructions">Enter last 4 of phone</div>
              <div className="challenge-section">
                <input
                  onChange={(event) => setChallengeResponse(event.target.value)}
                  value={challengeResponse}
                  placeholder="0 0 0 0"
                  pattern="[0-9]*"
                  className="challenge-input challenge-input-4-chars data-hj-allow"
                  maxLength={4}
                  autoFocus
                />
              </div>
            </>
          )}

          {status === OrderStatus.ready && !challengeKey && challengeType === ChallengeType.LAST_OF_EXTERNAL_ORDER && (
            <>
              <div className="challenge-instructions">Enter last 4 of order number</div>
              <div className="challenge-section">
                <input
                  onChange={(event) => setChallengeResponse(event.target.value)}
                  value={challengeResponse}
                  placeholder="0 0 0 0"
                  pattern="[0-9]*"
                  className="challenge-input challenge-input-4-chars data-hj-allow"
                  maxLength={4}
                  autoFocus
                />
              </div>
            </>
          )}

          {status === OrderStatus.ready && !challengeKey && challengeType === ChallengeType.FIRST_OF_LAST_NAME && (
            <>
              <div className="challenge-instructions">Enter last name initial</div>
              <div className="challenge-section">
                <h1>{displayInfo}</h1>
                <input
                  onChange={(event) => setChallengeResponse(event.target.value.toUpperCase())}
                  value={challengeResponse}
                  placeholder="?"
                  className="challenge-input challenge-input-1-char data-hj-allow"
                  maxLength={1}
                  autoFocus
                />
              </div>
            </>
          )}

          {status === OrderStatus.ready && !challengeKey && challengeType === null && (
            <>
              <div className="container container-full mt-s">
                <h3>Please ask the staff for your order</h3>
              </div>
            </>
          )}

          {status === OrderStatus.ready && challengeType !== null && (
            <div className="container">
              <div className="buttons">
                {openDeviceLoading ? (
                  <div className="button-placeholder">OPENING...</div>
                ) : (
                  <button className="button btn-success" onClick={handleChallengeValidation}>
                    OPEN LOCKER #{deviceDispayNumber} NOW
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="centered-box">
            <LockerImage dispayInfo={displayInfo} status={status} />
          </div>
        </div>
      ) : (
        status === OrderStatus.ready && (
          <div className="container centered-box mt-s">
            <h3>Get the order from the side counter</h3>
          </div>
        )
      )}

      {status === OrderStatus.fulfilled && (
        <div className="container centered-box">
          <h1>Order already picked up</h1>
        </div>
      )}

      <div className="container-full logo-container">
        <a href="https://goboxie.com" target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </div>
    </div>
  );
};
