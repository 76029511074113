import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Rating as RatingStars } from '@smastrom/react-rating';

import { CreateReviewPublic, CreateReviewPublicVariables } from '../../../types/gql/CreateReviewPublic';
import { CREATE_REVIEW_PUBLIC } from '../../../repositories/ReviewRepository';

import '@smastrom/react-rating/style.css';
import './styles.css';
import { Logo } from '../../Logo/Logo';

interface RatingRouteParams {
  codeId: string;
}

export const Review: FC = () => {
  const { codeId } = useParams() as unknown as RatingRouteParams;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [createReview, { loading }] = useMutation<CreateReviewPublic, CreateReviewPublicVariables>(
    CREATE_REVIEW_PUBLIC
  );

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    try {
      await createReview({
        variables: {
          codeId,
          rating,
          comment,
        },
      });

      setIsSubmitted(true);
    } catch (e) {
      console.error(e);

      // @ts-ignore
      const errorCode = e?.graphQLErrors[0]?.message;
      console.info(errorCode);

      switch (errorCode) {
        case 'PUBLIC_API_INCORRECT_CODEID':
          setIsSubmitted(true);
          break;
        default:
          alert(errorCode);
          break;
      }
    }
  };

  if (isSubmitted) {
    return (
      <div className="body ">
        <div className="container container-full">
          <h1>Thank you!</h1>
          <h3>Your feedback is very important to us.</h3>
          {rating > 3 && (
            <div className="container-full logo-container">
              <a href="https://goboxie.com" target="_blank" rel="noreferrer">
                <Logo />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="body page">
      <div className="container container-full m">
        <h1>Thank you!</h1>
        <h3>Please close the locker door.</h3>
      </div>
      <div className="container container-full mt-s">
        <h2>Did you like the lockers?</h2>
        <div className="container container-full mt-m">
          <RatingStars style={{ maxWidth: 250 }} value={rating} onChange={setRating} />
          <textarea
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            autoFocus
            placeholder="Why this rating?"
            className="review-textarea mt-m"
          />

          <button className="button mt-m" onClick={handleSubmit}>
            {loading ? 'Submitting' : 'Submit Review'}
          </button>
        </div>
      </div>
    </div>
  );
};
