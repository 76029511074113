import { FC } from 'react';
import { OrderStatus } from '../../types/gql/globalTypes';

import './styles.css';

interface LockerImageProps {
  dispayInfo?: string | null;
  status: OrderStatus;
}

export const LockerImage: FC<LockerImageProps> = ({ dispayInfo, status }) => {
  return (
    <div
      className={`locker-image-container ${status === OrderStatus.preparing && 'background-preparing'} ${
        status === OrderStatus.ready && 'background-ready'
      }`}
    >
      <span className="display-info">{dispayInfo}</span>
    </div>
  );
};
