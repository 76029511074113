import gql from 'graphql-tag';

export const GET_ORDER_PUBLIC = gql`
  query GetOrderPublic($codeId: ID!) {
    getOrderPublic(codeId: $codeId) {
      attemptsRemaining
      displayInfo
      preparingAt
      status
      orderDisplayId
      deviceDispayNumber
      codeId
      challengeType
      location {
        address {
          zipCode
          state
          line2
          line1
          city
        }
        phone
        name
        avgPrepTime
      }
      preventStalePickup
      isStale
      isOrderAssigned
    }
  }
`;

export const ON_ORDER_UPDATED_PUBLIC = gql`
  subscription OnOrderUpdatedPublic($codeId: ID!) {
    onOrderUpdatedPublic(codeId: $codeId) {
      codeId
      isOrderAssigned
      deviceDispayNumber
      status
      displayInfo
      challengeType
      isStale
    }
  }
`;
